.cardCustomContent {
  height: 100px;
  display: flex;
  align-items: end;
  padding: 16px 0px;
}

.cardInfoContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.cardIconContainer>svg {
  width: 42px;
  height: 48px;
}

.cardIconContainer {
  display: flex;
  justify-content: center;
  margin: 13px 0px;
}

.pageName {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #222222;
  font-style: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.lastOpened {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #666666;
  font-style: normal;
}