.wrapper {
  position: fixed;
  left: 70px;
  right: 0;
  bottom: 0;
  top: 0;
}

.wrapper .header {
  background: #fff;
  height: 60px;
  margin-left: 10px;
}

.wrapper .breadcrumb {
  background: #fff;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 24px;
}

.wrapper .breadcrumb .breadcrumb-compliance-company {
  font-weight: 400;
  line-height: inherit;
  font-size: 13px;
  font-family: Segoe UI;
  color: #495057;
  font-style: normal;
  display: inline-block;
  box-sizing: inherit;
}

.wrapper .breadcrumb > section {
  margin-left: 10px;
  padding-top: 14px;
  padding-bottom: 14px;
}

.wrapper .divider {
  border-top: 1px solid #dddddd;
}

.wrapper .container {
  flex-grow: 1;
  background: #eee;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  height: 85%;
  bottom: 50px;
  background-color: #f6f6f6;
}

.wrapper .footer {
  position: fixed;
  left: 70px;
  right: 0;
  bottom: 0;
  background-color: white;
}
