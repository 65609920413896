.side-nav {
    position: absolute;
    left: 0;
    top: 0;
    width: 70px;
    height: 100%;
    background: #FFFFFF;
    color: black;
    text-align: center;
    z-index: 1030;
}